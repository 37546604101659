// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../scss/application.scss';

require("@rails/ujs").start();
require("turbolinks").start();

require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('jquery');
require('popper.js');
require('bootstrap');

document.addEventListener("turbolinks:load", () => {
  $(document).on('change','#membership_delivery_attributes_address_id',function(){
    if ($(this).val() == '') {
      $('#new_delivery_address_fieldset').prop('disabled', false);
    }
    else {
      $('#new_delivery_address_fieldset').prop('disabled', true);
    }
  });

  $(".eye-icon").on('change',function() {
    var $target = $(this).closest('div.eye-password').find('.eye-field').first();
    if ($target.attr('type') === 'password') {
        $target.attr('type', 'text');
    } else {
        $target.attr('type', 'password');
    }
  });

  $('#execute_remote_payment_button').on('click', function(e) {
    $(this).children('span').removeClass('d-none');
  });
  
  document.querySelector('.custom-file-input').addEventListener('change',function(e){
    var fileName = document.getElementById("custom_file_input_field").files[0].name;
    var nextSibling = e.target.nextElementSibling
    nextSibling.innerText = fileName
  });  
});

import '../src/add-eu-banner'





